import {
  QUERY_POSTS,
  SET_LOADING,
  SET_ERROR,
  GET_POST,
  QUERY_INIT,
  INIT_POST,
  SET_QUERY_UPLOAD_DATES,
  SET_QUERY_CREATION_DATES,
  SET_QUERY_TAGS,
  SET_QUERY,
  SET_QUERY_SEARCH,
  REPLACE_QUERY_TAGS,
  SET_QUERY_DURATION,
  DELETE_POST,
  ADD_POST,
  SET_POST_FETCHING,
  FETCH_MORE_POSTS,
  SYNC_REQUEST,
  SET_POST_TOOLBAR_STATE,
  SET_CURRENT_POST,
} from "./../types";
import { DURATION_MIN, DURATION_MAX } from "config";

export default function PostReducer(state, action) {
  const { payload } = action;

  switch (action.type) {
    case QUERY_POSTS:
      return {
        ...state,
        posts: payload.posts,
        remainingPosts: payload.remainingPosts,
        query: payload.query,
        loadingTime: payload.loadingTime,
        filters: { ...state.filters, ...payload.filters },
        isLoading: false,
      };
    case DELETE_POST:
      return {
        ...state,
        posts: state.posts.filter((post) => post.post_hash !== payload),
        totalPosts: state.totalPosts - 1,
        isLoading: false,
      };
    case ADD_POST:
      return {
        ...state,
        posts: [payload, ...state.posts],
        totalPosts: state.totalPosts + 1,
        isLoading: false,
      };
    case FETCH_MORE_POSTS:
      return {
        ...state,
        posts: [...state.posts, ...payload.posts],
        remainingPosts: payload.remainingPosts,
        currentPage: state.currentPage + 1,
        loadingTime: payload.loadingTime,
        filters: { ...state.filters, ...payload.filters },
        isLoading: false,
        isFetching: false,
      };

    case GET_POST:
      return {
        ...state,
        post: payload,
        isLoading: false,
      };
    case SET_CURRENT_POST:
      return {
        ...state,
        post: payload,
        isLoading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload || true,
      };
    case SET_POST_FETCHING:
      return {
        ...state,
        isFetching: payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case SET_QUERY_UPLOAD_DATES:
      return {
        ...state,
        queryStartUploadDate: payload[0],
        queryEndUploadDate: payload[1],
        isLoading: false,
      };
    case SET_QUERY_CREATION_DATES:
      return {
        ...state,
        queryStartCreationDate: payload[0],
        queryEndCreationDate: payload[1],
        isLoading: false,
      };
    case SET_QUERY_TAGS:
      return {
        ...state,
        queryTags: state.queryTags.includes(payload)
          ? state.queryTags.filter((a) => a !== payload)
          : [...state.queryTags, payload],
        isLoading: false,
      };
    case REPLACE_QUERY_TAGS:
      return {
        ...state,
        queryTags: payload,
        isLoading: false,
      };
    case SET_QUERY:
      return {
        ...state,
        query: payload,
        isLoading: false,
      };
    case SET_POST_TOOLBAR_STATE:
      return {
        ...state,
        toolBarState: payload,
        isLoading: false,
      };
    case SET_QUERY_DURATION:
      return {
        ...state,
        queryDuration: payload,
        isLoading: false,
      };
    case SET_QUERY_SEARCH:
      return {
        ...state,
        querySearch: payload,
        isLoading: false,
      };
    case INIT_POST:
      return { ...state, post: {} };
    case QUERY_INIT:
      return {
        query: "",
        posts: [],
        filters: {
          Status: [],
          Channel: [],
          Exceptions: [],
          Duration: [],
          "Global Tags": [],
          "Private Tags": [],
          Categories: [],
          Brands: [],
          Features: [],
          Mediums: [],
          Confirmations: [],
          Manufacturers: [],
        },
        post: {},
        error: "",
        isLoading: false,
        isFetching: false,
        queryStartUploadDate: "",
        queryEndUploadDate: "",
        queryStartCreationDate: "",
        queryEndCreationDate: "",
        currentPage: 0,
        totalPosts: 0,
        loadingTime: 0,
        remainingPosts: 0,
        querySearch: "",
        queryTags: [],
        queryDuration: {
          min: DURATION_MIN,
          max: DURATION_MAX,
        },
      };
    case SYNC_REQUEST:
      return action.payload;
    default:
      return state;
  }
}
